import './App.css';
import {Suspense, useEffect, useRef, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import Text from "./components/Text";
import axios from "axios";
import Lottie from "lottie-react";
import lottieTouch from "./touch.json"
import ok from './ok.json'

const itemVariants = {
    open: {
        opacity: 1,
        y: 0,
        transition: {y: {type: "spring", stiffness: 300, damping: 10}}
    },
    closed: {
        opacity: 0,
        y: 20,
        transition: {duration: 0.2}
    }
};

function App() {
    const board = useRef(null)
    const [win, setWin] = useState(false)
    const clickAnim = useRef(null)
    const okAnim = useRef(null)
    const [animPos, setAnimPos] = useState({x: 0, y: 0})
    const bgAudio = useRef()
    const successAudio = useRef()
    const errorAudio = useRef()
    const [reveal, setReveal] = useState(false)
    const [selectedItem, setSelectedItem] = useState([])
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [answers, setAnswers] = useState([])
    const dimFactor = 0.9
    const fontDimFactor = 0.018
    const [game, setGame] = useState(null)
    const [canHideLogo, setCanHideLogo] = useState(false)
    const [canPlay, setCanPlay] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [clicks, setClicks] = useState([])
    const [dim, setDim] = useState('')
    const [audioActive, setAudioActive] = useState(false)

    useEffect(() => {
        document.addEventListener('click', function (e) {
            // console.log(e)
            setAnimPos({
                x: e.x, y: e.y
            })
        })
    }, [])

    useEffect(() => {
        if (answers.length === 16) {
            console.log('Hai vinto!')
            setWin(true)
            setTimeout(() => {
                // okAnim.current.goTo(0, true)
                setWin(false)
            }, 3000)
        }
    }, [answers])

    useEffect(() => {
        if (win) {
            okAnim.current.setSpeed(0.5)
            okAnim.current.goToAndPlay(0, true)
            setTimeout(() => {
                reset()
            }, 3000)
        }
    }, [win])

    useEffect(() => {
        // console.log('playy')
        clickAnim.current.goToAndPlay(0, true)
    }, [animPos])

    useEffect(() => {
        axios.get('https://visitmalta.ms3dev.it/wp-admin/admin-ajax.php?action=game').then(res => {
            console.log(res.data)
            setData(res)
        }).catch(e => {
            console.log(e)
            setData({
                data: JSON.parse(localStorage.getItem('game'))
            })
        })
    }, [])

    function setData(res) {
        setGame(res.data)
        localStorage.setItem('game', JSON.stringify(res.data))
        document.getElementsByTagName('body')[0].style.backgroundImage = "url('" + res.data.background + "')"
        setTimeout(() => {
            setReveal(true)
            // revelation()
            checkBoardDimension()
        }, 500)
    }

    useEffect(() => {

        window.addEventListener('resize', checkBoardDimension)
        checkBoardDimension()
        return () => {
            window.removeEventListener('resize', checkBoardDimension)
        }
    }, [board])

    function revelation() {
        // setTimeout(() => {
        setCanHideLogo(true)
        setTimeout(() => {
            setCanPlay(true)
        }, 1000)
        // }, 2000)
    }


    /* View in fullscreen */
    function openFullscreen() {
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
            setFullScreen(true)
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
            setFullScreen(true)
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
            setFullScreen(true)
        }
    }

    /* Close fullscreen */
    function closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullScreen(false)
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
            setFullScreen(false)
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
            setFullScreen(false)
        }
    }

    function checkBoardDimension() {
        if (!board.current) return
        let ww = window.innerWidth
        let wh = window.innerHeight
        setDim(ww + ' x ' + wh + ' - ' + window.devicePixelRatio)
        let landscape = ww >= wh
        let targetDim = landscape ? wh : ww
        let d = (targetDim * dimFactor)
        console.log(d, landscape)
        if (landscape) {
            if (d > 1000) {
                d = 1000
                board.current.style.width = d + 'px'
                board.current.style.height = d + 'px'
                return
            }
        } else {
            if (d > 800) {
                // d = 800
                // board.current.style.width = d + 'px'
                // board.current.style.height = d + 'px'
                // return
            }
        }
        board.current.style.width = d + 'px'
        board.current.style.height = d + 'px'
        let text = document.querySelectorAll('.text')
        if (text) {
            text.forEach(e => {
                e.style.fontSize = (targetDim * fontDimFactor) + 'px'
            })
        }
    }

    function reset() {
        setReveal(false)
        setCanHideLogo(false)
        setCanPlay(false)
        setCurrentIndex(-1)
        setAnswers([])
        setSelectedItem([])
        setTimeout(() => {
            setReveal(true)
            // revelation()
        }, 1000)
    }

    useEffect(() => {

    }, [currentIndex])

    function getSelected(i, k) {
        return selectedItem.length > 0 && selectedItem[0] === i && selectedItem[1] === k
    }

    function getIndex(i, k) {
        return (k + 1) + (4 * i)
    }

    function inAnswers(i, k) {
        return answers.find(e => e[0] === i && e[1] === k) != null
    }

    useEffect(() => {
        console.log(audioActive)
        if (!bgAudio.current) return
        if (audioActive) {
            if (!bgAudio.current.isPlaying)
                bgAudio.current.play()
        }
        bgAudio.current.muted = !audioActive
        successAudio.current.muted = !audioActive
        errorAudio.current.muted = !audioActive
    }, [audioActive])

    function playAudio() {
        setAudioActive(!audioActive)
    }

    return (
        <Suspense fallback={null}>
            <audio ref={bgAudio} loop style={{
                position: 'absolute',
                opacity: 0,
                visibility: 'hidden'
            }}>
                <source src={'mallet.mp3'}/>
            </audio>
            <audio ref={successAudio} style={{
                position: 'absolute',
                opacity: 0,
                visibility: 'hidden'
            }}>
                <source src={'success.mp3'}/>
            </audio>
            <audio ref={errorAudio} style={{
                position: 'absolute',
                opacity: 0,
                visibility: 'hidden'
            }}>
                <source src={'error.mp3'}/>
            </audio>
            {/*<div style={{position: 'fixed', top: 10, left: 10, color: '#fff', fontWeight: 'bold'}}*/}
            {/*     className="debug-dim">{dim}</div>*/}
            <div className="App">
                <div className="">
                    <img className={
                        'logo'
                    } style={{
                        height: '13vh',
                        marginTop: '30px'
                    }} src={'logo.svg'}/>
                </div>
                {
                    <motion.div ref={board} className="board"
                                initial={"closed"}
                                variants={{
                                    not_play: {
                                        translateY: '-63%',
                                        translateX: '-50%',
                                        transition: {
                                            duration: 0.5
                                        },
                                    },
                                    play: {
                                        transition: {
                                            duration: 0.5
                                        },
                                        translateY: '-50%',
                                        translateX: '-50%'
                                    },
                                }}
                                animate={[reveal ? "open" : "closed", canPlay ? 'play' : 'not_play']}>
                        <motion.div variants={{
                            open: {
                                transition: {
                                    staggerChildren: 0.08
                                }
                            },
                            closed: {
                                transition: {
                                    duration: 0.3
                                }
                            }
                        }} className="board-inner">
                            {
                                game && game.game.map((e, i) => {
                                    return e.game_row.map((f, k) => {
                                        return <motion.div key={'board-item-' + i + k} style={{
                                            paddingRight: '0.5%',
                                            paddingLeft: '0.5%',
                                            paddingBottom: '0.5%',
                                            paddingTop: '0.5%'
                                        }} className="board-item-outer" animate={{
                                            rotateZ: getSelected(i, k) ? [0, -2, 0, 2, 0] : 0
                                        }} transition={{
                                            ease: 'linear',
                                            duration: 0.3,
                                            bounce: 0.1,
                                            repeat: getSelected(i, k) ? Infinity : null
                                        }} variants={itemVariants}>
                                            <motion.div whileTap={{
                                                scale: !inAnswers(i, k) && canHideLogo ? 0.8 : 1
                                            }} animate={getSelected(i, k) ? {
                                                scale: 1,
                                            } : inAnswers(i, k) || !canHideLogo ? {
                                                scale: 1,
                                                rotateY: -180
                                            } : {
                                                scale: 1
                                            }}
                                                        transition={{
                                                            duration: !canPlay ? 2 : inAnswers(i, k) ? 2 : 0.8,
                                                            delay: !canPlay && canHideLogo ? getIndex(i, k) * 0.1 : 0,
                                                            // ease: [0.66, 0.75, 0.55, 1.49]
                                                            type: "spring",
                                                            bounce: !canPlay || getSelected(i, k) ? 0.3 : 0.6,
                                                        }}
                                                        onClick={() => {
                                                            if (inAnswers(i, k) || !canHideLogo) return;
                                                            let selected = getSelected(i, k)
                                                            if (!selected && selectedItem.length > 0) {
                                                                // scelto per secondo
                                                                let r = Number(game.game[i].game_row[k].related_to)
                                                                // scelto per primo
                                                                let rt = Number(game.game[selectedItem[0]].game_row[selectedItem[1]].related_to)
                                                                if (r === getIndex(selectedItem[0], selectedItem[1]) && rt === getIndex(i, k)) {
                                                                    console.log('Giusto')
                                                                    // successAudio.current.stop()
                                                                    // successAudio.current.pause().then(res => {
                                                                    successAudio.current.currentTime = 0;
                                                                    successAudio.current.play()
                                                                    // });
                                                                    setAnswers([
                                                                        ...answers,
                                                                        [i, k],
                                                                        selectedItem
                                                                    ])
                                                                } else {
                                                                    console.log('Sbagliato')
                                                                    // errorAudio.current.pause().then(res => {
                                                                    errorAudio.current.currentTime = 0;
                                                                    errorAudio.current.play()
                                                                    // });
                                                                }
                                                                setSelectedItem([])
                                                                setCurrentIndex(-1)
                                                                return
                                                            }
                                                            if (!selected) {
                                                                setSelectedItem([i, k])
                                                                setCurrentIndex(getIndex(i, k))
                                                            } else {
                                                                setSelectedItem([])
                                                                setCurrentIndex(-1)
                                                            }
                                                        }} className={'board-item-inner'}
                                                        style={{position: 'relative', height: '100%'}}>
                                                <motion.div className="board-item retro" style={{
                                                    backgroundImage: "url('/logo/logo-" + (getIndex(i, k) - 1) + ".png')"
                                                }}>

                                                </motion.div>
                                                {/*<motion.div className={'board-item ' + (getSelected(i, k) ? f.color : '')}>*/}
                                                <motion.div className={'board-item'}>
                                                    <Text image={f.image} text={f.title} classes={[f.color, f.type]}/>
                                                    {/*{*/}
                                                    {/*    getSelected(i, k) && !inAnswers(i, k) &&*/}
                                                    {/*    <Lottie animationData={lottieSelected}/>*/}
                                                    {/*}*/}
                                                    {/*{*/}
                                                    {/*    inAnswers(i, k) && <Lottie loop={false} animationData={lottieTest}/>*/}
                                                    {/*}*/}
                                                </motion.div>
                                            </motion.div>
                                        </motion.div>
                                    })
                                })
                            }
                        </motion.div>

                        {
                            <Lottie lottieRef={okAnim} className={'win'} style={{
                                position: 'absolute',
                                pointerEvents: 'none'
                            }} loop={false} autoplay={false} animationData={ok}/>
                        }
                    </motion.div>
                }
                <AnimatePresence>
                    {!canPlay && <motion.button initial={{opacity: 0}}
                                                animate={{opacity: 1}}
                                                exit={{opacity: 0}} onClick={revelation} className="gioca">
                        <b>GIOCA</b>
                    </motion.button>}
                </AnimatePresence>
                <div className="buttons">
                    <button onClick={reset}>
                        <img src={'reset.svg'}/>
                    </button>
                    <button onClick={playAudio}>
                        <img src={audioActive ? 'audio-active.svg' : 'audio.svg'}/>
                    </button>
                    <button className={fullScreen ? 'active' : ''} onClick={() => {
                        if (!fullScreen) {
                            openFullscreen()
                        } else {
                            closeFullscreen()
                        }
                    }}>
                        <img src={fullScreen ? 'full-back.svg' : 'full.svg'}/>
                    </button>
                </div>
            </div>
            {/*{clicks.map((e, i) => <Lottie lottieRef={clickAnim} key={'anima_' + i} style={{*/}
            {/*    position: 'fixed',*/}
            {/*    top: e.y,*/}
            {/*    left: e.x,*/}
            {/*    width: 30,*/}
            {/*    height: 30*/}
            {/*}} loop={false} autoplay={true} animationData={lottieTouch}/>)}*/}
            <Lottie lottieRef={clickAnim} style={{
                position: 'fixed',
                top: animPos.y - 50,
                left: animPos.x - 50,
                width: 100,
                height: 100,
                pointerEvents: 'none'
            }} loop={false} autoplay={false} animationData={lottieTouch}/>
        </Suspense>
    );
}

export default App;
